.question-answered {
    background-color: rgba(25, 179, 148, 0.05);
}

.question-content .question-root {
    border: 1px solid lightgray;
    border-radius: 5px;
    border-left: 4px solid #18a689;
    padding: 20px;
    margin: 10px 0;
}

.question-content .question-root-checkboxes {
    border-left: 4px solid orange;
}

.question-title-required {
    color: #e60a3e;
}

.sd-btn--action {
    background-color: #093088;
}

.sd-btn--action:hover {
    background-color: #093088;
}

.sd-rating__item--selected {
    background-color: #093088;

}

.sd-rating__item--selected:focus-within {
}

.sd-rating__item--selected:focus-within {
    box-shadow: var(--sjs-shadow-small-reset, 0px 0px 0px 0px rgba(0, 0, 0, 0.15)),inset 0 0 0 4px var(--sjs-general-backcolor, var(--background, #fff)),0 0 0 2px var(--sjs-primary-backcolor, var(--primary, #093088));
}


.sd-input {
    --sjs-internal-font-editorfont-size: var(--sjs-mobile-font-editorfont-size, var(--sjs-font-editorfont-size, var(--sjs-font-size, 16px)));
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: static;
    width: 100%;
    box-sizing: border-box;
    padding: calc(1.5*(var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2*(var(--sjs-base-unit, var(--base-unit, 8px))));
    line-height: calc(1.5*(var(--sjs-internal-font-editorfont-size)));
    font-family: var(--sjs-font-editorfont-family, var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family))));
    font-weight: var(--sjs-font-editorfont-weight, 400);
    color: var(--sjs-font-editorfont-color, var(--sjs-general-forecolor, rgba(0, 0, 0, 0.91)));
    font-size: var(--sjs-internal-font-editorfont-size);
    background-color: var(--sjs-editorpanel-backcolor, var(--sjs-editor-background, var(--sjs-general-backcolor-dim-light, var(--background-dim-light, #f9f9f9))));
    border: none;
    border-radius: var(--sjs-editorpanel-cornerRadius, var(--sjs-corner-radius, 4px));
    text-align: start;
    box-shadow: var(--sjs-shadow-inner, inset 0px 1px 2px 0px rgba(0, 0, 0, 0.15)),0 0 0 0px var(--sjs-primary-backcolor, var(--primary, #093088));
    transition: box-shadow var(--sjs-transition-duration, 150ms);
}

.sd-input:focus {
    box-shadow: var(--sjs-shadow-inner-reset, inset 0px 0px 0px 0px rgba(0, 0, 0, 0.15)),0 0 0 2px var(--sjs-primary-backcolor, var(--primary, #093088));
}
